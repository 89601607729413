import React from "react";
import styles from "./header.module.scss";
import SubmissionForm from "../submission-form/SubmissionForm";
import HamburgerButton from "./hamburger-button";

const Header = ({
    setSidebarIsOpen = () => {},
    sidebarIsOpen = false,
    mapDoneLoading = false
}) => {
    return (
        <header className={styles.header}>
            <HamburgerButton
                isOpen={sidebarIsOpen}
                action={() => setSidebarIsOpen(!sidebarIsOpen)}
                className={styles["hamburger"]}
            />
            <div className={styles.logo}>
                <img
                    src={`${process.env.PUBLIC_URL}/images/slicemap-logo.svg`}
                    alt=""
                />
            </div>
            <h1>$1 Pizza NYC</h1>
            <SubmissionForm mapDoneLoading={mapDoneLoading}></SubmissionForm>
        </header>
    );
};

export default Header;
