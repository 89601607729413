export function isPlaceOpen(periods) {
    if (!periods[0].close) {
        return true;
    }
    const date = new Date();
    const day = date.getDay();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const currentTime = day * 2400 + hours * 100 + minutes;
    // calculate the current hours:minutes into the week (HHMM)
    let currentlyOpen = false;
    for (let i = 0; i < periods.length; i += 1) {
        const openTime =
            periods[i].open.day * 2400 + Number(periods[i].open.time);
        const closeTime =
            periods[i].close.day * 2400 + Number(periods[i].close.time);
        // check to see if the current time into the week is between the
        // opening and closing time values for each day of the week
        if (currentTime >= openTime && currentTime < closeTime) {
            currentlyOpen = true;
            break;
        } else if (currentTime < openTime) {
            break;
        }
    }
    return currentlyOpen;
}

function timeConvert(time) {
    const hours = Number(time.slice(0, 2));
    const minutes = Number(time.slice(2));
    return `${hours % 12 || 12}${minutes ? `:${minutes}` : ""}${
        hours < 12 ? "AM" : "PM"
    }`;
}

export function isCurrentlyOpen(periods) {
    if (!periods[0].close) {
        return {
            isOpen: true,
            openUntil: "24hr"
        };
    }
    const date = new Date();
    const day = date.getDay();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const currentTime = day * 2400 + hours * 100 + minutes;
    // calculate the current hours:minutes into the week (HHMM)
    for (let i = 0; i < periods.length; i += 1) {
        const openTime =
            periods[i].open.day * 2400 + Number(periods[i].open.time);
        const closeTime =
            periods[i].close.day * 2400 + Number(periods[i].close.time);
        // check to see if the current time into the week is between the
        // opening and closing time values for each day of the week
        if (currentTime >= openTime && currentTime < closeTime) {
            return {
                isOpen: true,
                openUntil: timeConvert(periods[i].close.time)
            };
        }
        if (currentTime < openTime) {
            break;
        }
    }
    return {
        isOpen: false,
        openUntil: ""
    };
}

export function getFormattedAddressComponents(addrComp) {
    const newAddr = {};
    const address1 = [];
    addrComp.forEach(el => {
        if (el.types.includes("post_box")) {
            address1.push(el.long_name);
        } else if (el.types.includes("street_number")) {
            address1.push(el.long_name);
        } else if (el.types.includes("route")) {
            address1.push(el.short_name);
        } else if (el.types.includes("subpremise")) {
            newAddr.address_2 = el.long_name;
        } else if (el.types.includes("sublocality")) {
            newAddr.neighborhood = el.long_name;
        } else if (el.types.includes("locality")) {
            newAddr.city = el.long_name;
        } else if (el.types.includes("administrative_area_level_1")) {
            newAddr.state_code = el.short_name;
        } else if (el.types.includes("postal_code")) {
            newAddr.zipCode = el.short_name;
        }
    });
    newAddr.address_1 = address1.join(" ");

    if (!newAddr.city) {
        const neighborhood = addrComp.find(comp => {
            return comp.types.includes("sublocality");
        });

        if (neighborhood) {
            newAddr.city = neighborhood.long_name;
        }
    }

    return newAddr;
}
