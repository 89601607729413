import React from "react";
import RowInput from "../row-input/row-input";
import ListItem from "../list-item/ListItem";

import styles from "./Sidebar.module.scss";

const Sidebar = ({
    locations,
    menuItemClickHandler,
    sidebarIsOpen,
    setSidebarIsOpen,
    shopsToShow,
    setShopsToShow
}) => {
    const listLocations = () => {
        if (locations === null) {
            return null;
        }
        return locations.map(location => (
            <ListItem
                location={location}
                key={location.place_id}
                menuItemClickHandler={menuItemClickHandler}
                setSidebarIsOpen={setSidebarIsOpen}
            />
        ));
    };

    return (
        <div
            className={`${styles.sidebar} ${
                sidebarIsOpen ? styles.visible : styles.hidden
            }`}
        >
            <div className={styles["sidebar-wrapper"]}>
                <RowInput
                    options={[
                        {
                            label: "Open Now",
                            value: "locations-open"
                        },
                        { label: "All Shops", value: "locations-all" }
                    ]}
                    value={shopsToShow}
                    onChange={option => setShopsToShow(option.value)}
                />
                <div className={styles["sidebar-content"]}>
                    {listLocations()}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
