// takes props:
// apiKey:        string
// mapOptions:    object
// mapID:         string
// onMapLoad:     function(mapOject)
// mapLibraries:  array[string]

// Function Version

import React, { useEffect, useCallback } from "react";

const GoogleMap = ({
    apiKey = "",
    mapOptions = {},
    mapID = "google-map",
    onMapLoad = () => {},
    mapLibraries = []
}) => {
    const onScriptLoad = useCallback(() => {
        const map = new window.google.maps.Map(
            document.getElementById(mapID),
            mapOptions
        );
        onMapLoad(map);
    }, [mapID, mapOptions, onMapLoad]);

    useEffect(() => {
        if (window.google) {
            onScriptLoad();
        } else {
            let loadScript = document.createElement("script");
            loadScript.type = "text/javascript";
            loadScript.src = `https://maps.google.com/maps/api/js?key=${apiKey}${
                mapLibraries.length
                    ? `&libraries=${mapLibraries.join(",")}`
                    : ""
            }`;
            var x = document.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(loadScript, x);
            loadScript.addEventListener("load", e => {
                onScriptLoad();
            });
        }
    }, [apiKey, mapLibraries, onScriptLoad]);

    return <div id={mapID} />;
};

export default props => useCallback(<GoogleMap {...props} />, []);
