import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";

const initFirebase = () => {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID
    };

    firebase.initializeApp(firebaseConfig);
    return true;
};

const getPhotoURL = (fileName, size) => {
    const prefix = (size => {
        switch (size) {
            case "sm":
                return "thumb@64_";
            case "md":
                return "thumb@128_";
            case "lg":
                return "thumb@256_";
            default:
                return "";
        }
    })(size);
    // const filename = location.photos[0].filename;
    var storage = firebase.storage();
    var photoRef = storage.ref(`store-photos/${prefix}${fileName}`);
    return photoRef.getDownloadURL();
};

export { initFirebase, getPhotoURL };
export default firebase;
