import React, { useState, useCallback, useEffect } from "react";
import styles from "./ListItem.module.scss";
import { getPhotoURL } from "../firebase/firebase";
import ListItemIcon from "../list-item-icon/ListItemIcon";

const ListItem = ({ location, menuItemClickHandler, setSidebarIsOpen }) => {
    const { filename } = location.photos[0];
    const [photoUrl, setPhotoUrl] = useState("");

    const urlCallback = useCallback(() => {
        async function getPhoto() {
            const photoURL = await getPhotoURL(filename, "md");
            setPhotoUrl(photoURL);
        }
        getPhoto();
    }, [filename]);

    useEffect(urlCallback, [filename]);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={styles["list-item"]}
            onClick={() => {
                // setOpen(!open);
                menuItemClickHandler(location);
                setSidebarIsOpen(false);
            }}
        >
            <div className={styles["item-preview"]}>
                <ListItemIcon
                    open={
                        location.is_open_now === null
                            ? null
                            : location.is_open_now
                    }
                    isOpen={
                        location.is_open_now === null ? null : location.is_open
                    }
                    photo={photoUrl}
                />
                <div className={styles["item-content"]}>
                    {/* Store Name */}
                    <div className={styles["list-item-header"]}>
                        <h3 className={styles["store-name"]}>
                            {location.name}
                        </h3>
                        <span className={styles.rating}>
                            <span className={styles.star} />
                            {location.rating}
                        </span>
                    </div>
                    <h5 className={styles.address}>
                        {location.formatted_address_components.address_1}
                    </h5>
                    <h5 className={styles.address}>
                        {`${location.formatted_address_components.neighborhood}, ${location.formatted_address_components.state_code} ${location.formatted_address_components.zipCode}`}
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default ListItem;

// import React, { useState, useCallback, useEffect } from "react";
// import styles from "./ListItem.module.scss";
// import "./ListItem.scss";
// import firebase from "../firebase/firebase";
// import ListItemIcon from "../list-item-icon/ListItemIcon";
// import Slider from "react-slick";

// const ListItem = ({ location, menuItemClickHandler }) => {
//     const filename = location.photos[0].filename;
//     const [photoUrl, setPhotoUrl] = useState("");
//     const [open, setOpen] = useState(false);

//     const [storage, setStorage] = useState(null);
//     const [photoList, setPhotoList] = useState([]);

//     const urlCallback = useCallback(() => {
//         let tempStorage = firebase.storage();
//         setStorage(tempStorage);
//         var photoRef = tempStorage.ref(`store-photos/thumb@128_${filename}`);
//         photoRef.getDownloadURL().then(url => setPhotoUrl(url));
//     }, [filename]);

//     useEffect(urlCallback, [filename]);

//     const populatePhotoList = () => {
//         if (
//             open &&
//             !photoList.length &&
//             location.photos &&
//             location.photos.length
//         ) {
//             Promise.all(
//                 location.photos.map(photo => {
//                     var photoRef = storage.ref(
//                         `store-photos/thumb@128_${photo.filename}`
//                     );
//                     return photoRef.getDownloadURL();
//                 })
//             )
//                 .then(res => {
//                     console.log(res);
//                     setPhotoList(res);
//                 })
//                 .catch(err => console.log(err));
//         }
//     };

//     useEffect(populatePhotoList, [open]);

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 3
//     };

//     return (
//         <div
//             className={styles["list-item"]}
//             onClick={() => {
//                 setOpen(!open);
//                 menuItemClickHandler(location);
//             }}
//         >
//             <div className={styles["item-preview"]}>
//                 <ListItemIcon
//                     open={
//                         location.is_open_now === null
//                             ? null
//                             : location.is_open_now
//                     }
//                     photo={photoUrl}
//                 />
//                 <div className={styles["item-content"]}>
//                     {/* Store Name */}
//                     <div className={styles["list-item-header"]}>
//                         <h3 className={styles["store-name"]}>
//                             {location.name}
//                         </h3>
//                         <span className={styles["rating"]}>
//                             <span className={styles["star"]} />
//                             {location.rating}
//                         </span>
//                     </div>
//                     <h5 className={styles.address}>
//                         {location.formatted_address}
//                     </h5>
//                 </div>
//                 {/* Store rating and open */}
//             </div>
//             <div className={styles["item-details"]} hidden={!open}>
//                 <div className={styles["store-hours"]}>
//                     {location.opening_hours
//                         ? location.opening_hours.weekday_text.map(hours => (
//                               <h5>{hours}</h5>
//                           ))
//                         : null}
//                 </div>
//                 {photoList.length && (
//                     <Slider {...settings}>
//                         {photoList.map(url => (
//                             <div>
//                                 <div className="photo-container">
//                                     <img
//                                         src={url}
//                                         alt=""
//                                         className="slick-photo"
//                                     />
//                                 </div>
//                             </div>
//                         ))}
//                     </Slider>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ListItem;
