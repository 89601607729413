import React, { useEffect, useState } from "react";
import haversine from "haversine";
import ReactGA from "react-ga";
import "./App.scss";
import GoogleMap from "./components/google-map/GoogleMap";
import Header from "./components/header/header";
import MapContainer from "./components/map-container/MapContainer";
import Sidebar from "./components/sidebar/Sidebar";
import HomeContainer from "./components/home-container/HomeContainer";
import firebase, {
    initFirebase,
    getPhotoURL
} from "./components/firebase/firebase";
import BlueGreen from "./map-styles/blue-green";
import {
    isPlaceOpen,
    getFormattedAddressComponents,
    isCurrentlyOpen
} from "./helpers/places";

let currentInfoWindow = null;
let isTrackingUser = false;
let mapObj = null;
let markersArr = null;
// let watchID = null;
let userMarker = null;

const createLocateButton = () => {
    const controlIcon = document.createElement("div");
    controlIcon.classList.add("locate-button-icon");
    const controlIconLight = document.createElement("div");
    controlIconLight.classList.add("locate-button-icon");
    controlIconLight.classList.add("light");
    const locateButton = document.createElement("div");
    locateButton.classList.add("locate-button");
    locateButton.appendChild(controlIcon);
    locateButton.appendChild(controlIconLight);
    return locateButton;
};

const updateStoreDistances = userPosition => {
    markersArr = markersArr.map(marker => {
        const markerPosition = {
            latitude: marker.geometry.location.lat,
            longitude: marker.geometry.location.lng
        };
        marker.distance = haversine(userPosition.coords, markerPosition, {
            unit: "mile"
        });
        return marker;
    });
};

const updateUserLocation = userPosition => {
    const { latitude: lat, longitude: lng } = userPosition.coords;
    userMarker.setPosition({ lat, lng });
    if (isTrackingUser) {
        mapObj.panTo({ lat, lng });
    }
    updateStoreDistances(userPosition);
};

const createUserMarker = position => {
    if (position) {
        const { latitude: lat, longitude: lng } = position.coords;
        userMarker = new window.google.maps.Marker({
            position: { lat, lng },
            title: "user-icon",
            map: mapObj,
            icon: `${process.env.PUBLIC_URL}/images/me.svg`
        });
        updateStoreDistances(position);
    }
    navigator.geolocation.watchPosition(updateUserLocation);
    // watchID = navigator.geolocation.watchPosition(updateUserLocation);
};

const goToUser = () => {
    mapObj.panTo(userMarker.getPosition());
    mapObj.setZoom(14);
    isTrackingUser = true;
};

const addLocateButton = map => {
    const locateButton = createLocateButton();
    locateButton.addEventListener("click", () => {
        if (userMarker) {
            goToUser();
        } else {
            navigator.geolocation.getCurrentPosition(position => {
                createUserMarker(position);
                goToUser();
            });
        }
    });
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        locateButton
    );
};

const updateBoundsWithMarkers = markers => {
    const bounds = new window.google.maps.LatLngBounds();

    for (let i = 0; i < markers.length; i += 1) {
        const lat = markers[i].getPosition().lat();
        const lng = markers[i].getPosition().lng();
        if (lat && lng) bounds.extend(markers[i].getPosition());
    }
    mapObj.addListener("click", () => {
        if (currentInfoWindow) {
            currentInfoWindow.close();
            currentInfoWindow = null;
        }
    });
    mapObj.fitBounds(bounds);
};

const getInfoWindowContent = async marker => {
    const photoURL = await getPhotoURL(marker.photos[0].filename, "lg");
    return `
        <div class="ratings-bagde">${
            marker.rating
        }<i class="fas fa-star"></i></div>
        <div class="open-closed-dot ${
            marker.is_open_now === null
                ? ""
                : marker.is_open_now
                ? "green"
                : "red"
        }">
        ${
            marker.is_open_now === null
                ? ""
                : marker.is_open_now
                ? `OPEN - ${marker.is_open.openUntil}`
                : "CLOSED"
        }</div>
        <img src="${photoURL}" alt="" />
        <div class="info-window-content">
        <div class="info-window-title">
        <h2>${marker.name}</h2>
        </div>
        <div class="info-window-location">${
            marker.formatted_address_components.address_1
        }${
        marker.formatted_address_components.address_2
            ? ` ${marker.formatted_address_components.address_2} `
            : ``
    }</div>
        <div class="info-window-location">${
            marker.formatted_address_components.neighborhood
        }, ${marker.formatted_address_components.state_code} ${
        marker.formatted_address_components.zipCode
    }
        </div>
        ${
            marker.distance
                ? `<span class="distance">${marker.distance.toFixed(
                      1
                  )} Miles Away</span>`
                : ``
        }
        <div class="iw-button-container">
        ${
            marker.place_id
                ? `<a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
                      marker.name
                  )}&destination_place_id=${marker.place_id}">`
                : `<a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
                      `${marker.name} ${marker.formatted_address}`
                  )}`
        } 
        <i class="fas fa-directions"></i>Directions</a>
        </div>
        </div>
        `;
};

const openInfoWindow = async marker => {
    const content = await getInfoWindowContent(marker);
    const infowindow = new window.google.maps.InfoWindow({ marker, content });
    if (currentInfoWindow) currentInfoWindow.close();
    infowindow.open(mapObj, marker);
    currentInfoWindow = infowindow;
};

const menuItemClickHandler = item => {
    if (
        currentInfoWindow &&
        currentInfoWindow.marker.place_id === item.place_id
    ) {
        currentInfoWindow.open(mapObj, currentInfoWindow.marker);
    } else {
        const currentMarker = markersArr.find(
            marker => marker.place_id === item.place_id
        );
        openInfoWindow(currentMarker);
    }
};

const markerClickHandler = marker => {
    openInfoWindow(marker);
};

const initGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_CODE, {
        titleCase: false
    });
};

const App = () => {
    const [mapDoneLoading, setMapDone] = useState(false);
    const [markersDoneLoading, setMarkersDone] = useState(false);
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
    const [shopsToShow, setShopsToShow] = useState("locations-open");
    const [menuItems, setMenuItems] = useState([]);
    const [initialLoadDone, setInitialLoadDone] = useState(false);

    // once firebase is initialized, load all stores
    const loadStores = () => {
        firebase
            .firestore()
            .collection("locations")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => {
                    const place = doc.data();
                    if (place.opening_hours) {
                        place.is_open_now = isCurrentlyOpen(
                            place.opening_hours.periods
                        ).isOpen;
                        place.is_open = isCurrentlyOpen(
                            place.opening_hours.periods
                        );
                    }
                    if (place.address_components) {
                        place.formatted_address_components = getFormattedAddressComponents(
                            place.address_components
                        );
                    }
                    return place;
                });
                markersArr = data;
                setMarkersDone(true);
                ReactGA.event({
                    category: "Database",
                    action: "Stores Loaded"
                });
                return true;
            })
            .catch(err => {
                ReactGA.event({
                    category: "Database",
                    action: `Error loading stores: ${err}`
                });
                return true;
            });
    };

    const loadMap = map => {
        mapObj = map;
        map.addListener("dragstart", () => {
            isTrackingUser = false;
        });
        addLocateButton(map);
        setMapDone(true);
    };

    const showHideClosedStores = () => {
        const newMenuItems = [];
        markersArr.forEach(marker => {
            if (shopsToShow === "locations-open" && !marker.is_open_now) {
                marker.setMap(null);
            } else {
                marker.setMap(mapObj);
                newMenuItems.push(marker);
            }
        });
        setMenuItems(newMenuItems);
    };

    useEffect(() => {
        initGA();
        initFirebase();
        loadStores();
    }, []);

    useEffect(() => {
        if (initialLoadDone && shopsToShow) {
            showHideClosedStores();
        }
    }, [initialLoadDone, shopsToShow]);

    // watch to see when the map and markers are done loading
    useEffect(() => {
        if (mapDoneLoading && markersDoneLoading) {
            markersArr = markersArr.map(marker => {
                const { lat, lng } = marker.geometry.location;
                const mapMarker = new window.google.maps.Marker({
                    ...marker,
                    position: { lat, lng },
                    map: mapObj,
                    icon: `${process.env.PUBLIC_URL}/images/pizza.svg`
                });
                window.google.maps.event.addListener(mapMarker, "click", () =>
                    markerClickHandler(mapMarker)
                );
                return mapMarker;
            });
            updateBoundsWithMarkers(markersArr);
            navigator.geolocation.getCurrentPosition(createUserMarker);
            setInitialLoadDone(true);
        }
    }, [mapDoneLoading, markersDoneLoading]);

    return (
        <div className="App">
            <Header
                sidebarIsOpen={sidebarIsOpen}
                setSidebarIsOpen={setSidebarIsOpen}
                mapDoneLoading={mapDoneLoading}
            />
            <HomeContainer>
                <Sidebar
                    locations={menuItems}
                    menuItemClickHandler={menuItemClickHandler}
                    sidebarIsOpen={sidebarIsOpen}
                    setSidebarIsOpen={setSidebarIsOpen}
                    shopsToShow={shopsToShow}
                    setShopsToShow={setShopsToShow}
                />
                <MapContainer>
                    <GoogleMap
                        apiKey={process.env.REACT_APP_GMAPS_API_KEY}
                        mapID="google-map"
                        onMapLoad={loadMap}
                        mapLibraries={["places"]}
                        mapOptions={{
                            mapTypeControl: false,
                            // scaleControl: true,
                            streetViewControl: false,
                            // rotateControl: true,
                            // fullscreenControl: false,
                            clickableIcons: false,
                            styles: BlueGreen,
                            zoom: 12,
                            center: {
                                lat: 40.712775,
                                lng: -74.005973
                            },
                            gestureHandling: "greedy"
                        }}
                    />
                </MapContainer>
            </HomeContainer>
        </div>
    );
};

export default App;
