import React from "react";
import "./HamburgerButton.scss";

const HamburgerButton = ({
    className = "",
    color = "#222",
    isOpen = false,
    action = () => {}
}) => {
    return (
        <div
            className={`hamburger-button ${className} ${
                isOpen ? " close" : ""
            }`}
            onClick={action}
        >
            <span className="line" style={{ backgroundColor: color }} />
            <span className="line" style={{ backgroundColor: color }} />
            <span className="line" style={{ backgroundColor: color }} />
        </div>
    );
};

export default HamburgerButton;
