import React, { useState } from "react";
import Geosuggest from "react-geosuggest";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getFormattedAddressComponents } from "../../helpers/places";
import "./geosuggest.scss";
import firebase from "../firebase/firebase";
import Loader from "../loader/Loader";
import ReactGA from "react-ga";
import styles from "./SubmissionForm.module.scss";

const checkLocationExists = async place_id => {
    return Promise.all([
        await firebase
            .firestore()
            .collection("locations")
            .doc(place_id)
            .get()
            .then(docSnapshot => {
                return docSnapshot.exists ? true : false;
            }),
        await firebase
            .firestore()
            .collection("submissions")
            .where("place_id", "==", place_id)
            .get()
            .then(querySnapshot => {
                return querySnapshot.docs.length ? true : false;
            })
    ]).then(results => results.reduce((a, b) => a || b));
};

const SubmissionForm = ({ mapDoneLoading = false, ...props }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [isRepeatShop, setIsRepeatShop] = useState(null);
    const [isFormSubmited, setFormSubmitted] = useState(false);

    const initialFormValues = {
        shopName: "",
        streetAddress: "",
        borough: "",
        place_id: ""
    };

    const toggleForm = e => {
        setFormOpen(!formOpen);
    };

    const onPlaceSelect = async (place, setValues) => {
        if (place) {
            const locationExists = await checkLocationExists(place.placeId);
            setIsRepeatShop(locationExists);
            const formattedAddress = getFormattedAddressComponents(
                place.gmaps.address_components
            );
            setValues({
                shopName: place.gmaps.name || "",
                streetAddress: formattedAddress.address_1 || "",
                borough: formattedAddress.neighborhood || "",
                place_id: place.placeId
            });
        }
    };

    const validate = values => {
        let errors = {};
        if (!values.shopName) {
            errors.shopName = "Shop Name Required";
        }
        if (!values.streetAddress) {
            errors.streetAddress = "Street Address Required";
        }
        if (!values.borough) {
            errors.borough = "Borough Required";
        }
        if (isRepeatShop) {
            errors.isRepeatShop = "This shop is a repeat";
        }
        return errors;
    };

    const onSubmit = (values, { setSubmitting }) => {
        firebase
            .firestore()
            .collection("submissions")
            .add({
                ...values,
                has_been_reviewed: false,
                date_added: new Date()
            })
            .then(docRef => {
                setSubmitting(false);
                setFormSubmitted(true);
                ReactGA.event({
                    category: "Database",
                    action: `Store submitted: ${docRef.id}`
                });
            })
            .catch(err => {
                ReactGA.event({
                    category: "Database",
                    action: `Error submitting a store: ${err}`
                });
            });
    };

    return (
        <>
            <button
                onClick={toggleForm}
                className={`primary-button ${styles["open-form-button"]}`}
            >
                <i className="fas fa-plus" />
                <span className={styles["button-text"]}>Add a Store</span>
            </button>
            <div
                className={`${styles["form-container"]} ${
                    formOpen ? styles.open : styles.closed
                }`}
            >
                <div className={styles.background} />
                <Formik
                    initialValues={initialFormValues}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({
                        isSubmitting,
                        setValues,
                        validateForm,
                        errors,
                        values,
                        dirty,
                        setFieldValue,
                        resetForm
                    }) => (
                        <article className={styles.modal}>
                            <span
                                className={styles["close-button"]}
                                onClick={() => {
                                    toggleForm();
                                    resetForm();
                                    setTimeout(() => {
                                        setFormSubmitted(false);
                                        setIsRepeatShop(null);
                                    }, 250);
                                }}
                            >
                                <i className="fas fa-times"></i>
                            </span>
                            {isSubmitting ? (
                                <div className={styles["loader-container"]}>
                                    <Loader color="darkgrey"></Loader>
                                </div>
                            ) : isFormSubmited ? (
                                <div className={styles["form-success"]}>
                                    <i className="far fa-check-circle"></i>
                                    <h2>Success!</h2>
                                </div>
                            ) : (
                                <>
                                    <section className={styles.header}>
                                        <h2>
                                            Submit a New <b>$1</b> Slice Shop
                                        </h2>
                                        {mapDoneLoading && (
                                            <Geosuggest
                                                googleMaps={window.google.maps}
                                                minLength={3}
                                                placeholder="Seach Google Maps"
                                                onSuggestSelect={async place =>
                                                    onPlaceSelect(
                                                        place,
                                                        setValues
                                                    )
                                                }
                                                country="us"
                                                bounds={{
                                                    // The bounds of NYC
                                                    north: 40.91553277599955,
                                                    east: -73.70000906255197,
                                                    south: 40.496115395170854,
                                                    west: -74.25559136249367
                                                }}
                                                types={["establishment"]}
                                                onChange={() => {
                                                    setIsRepeatShop(null);
                                                    setFieldValue(
                                                        "place_id",
                                                        ""
                                                    );
                                                    validateForm(values);
                                                }}
                                            />
                                        )}
                                        {isRepeatShop === true && (
                                            <div
                                                className={`${styles["notification"]} ${styles["repeat"]}`}
                                            >
                                                <i className="far fa-times-circle"></i>
                                                This shop has already been added
                                                or submitted
                                            </div>
                                        )}
                                        {isRepeatShop === false && (
                                            <div
                                                className={`${styles["notification"]} ${styles["new"]}`}
                                            >
                                                <i className="far fa-check-circle"></i>
                                                This is a new shop!
                                            </div>
                                        )}
                                    </section>
                                    <Form className={styles.content}>
                                        <Field
                                            placeholder="Shop Name"
                                            name="shopName"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="shopName"
                                            component="div"
                                            className={styles["error-message"]}
                                        />
                                        <Field
                                            placeholder="Street Address"
                                            name="streetAddress"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="streetAddress"
                                            component="div"
                                            className={styles["error-message"]}
                                        />
                                        <Field
                                            placeholder="Borough"
                                            name="borough"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            name="borough"
                                            component="div"
                                            className={styles["error-message"]}
                                        />
                                        <Field
                                            name="place_id"
                                            type="text"
                                            hidden
                                        />
                                        <button
                                            className={`primary-button ${styles["submit-button"]}`}
                                            type="submit"
                                            disabled={
                                                isSubmitting ||
                                                Object.entries(errors).length ||
                                                !dirty
                                            }
                                        >
                                            Submit
                                        </button>
                                    </Form>
                                </>
                            )}
                        </article>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default SubmissionForm;
