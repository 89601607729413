import React from "react";
import styles from "./ListItemIcon.module.scss";

const ListItemIcon = ({ open, photo, isOpen }) => {
    return (
        <div className={styles["item-icon"]}>
            <img src={photo} alt="" />
            <div
                className={`${styles["open-closed-dot"]} ${
                    open === null ? "" : open ? styles.green : styles.red
                }`}
            >
                {open === null
                    ? ""
                    : open
                    ? `OPEN - ${isOpen.openUntil}`
                    : "CLOSED"}
            </div>
        </div>
    );
};

export default ListItemIcon;
